import Vue from 'vue'
import App from './App.vue'
import { Message } from 'element-ui'
import router from './router'
import './plugins/element.js'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import './assets/bass.css'
import './assets/fonts/iconfont.css'

import md5 from 'js-md5'
// let Base64 = require('js-base64').Base64;
const moment = require('moment')


// console.log(Base64.decode('dmNUb2tlbj1mMDA4ODM4NzQwYTc0OTJjYTE2NTQ1OWMyZDBjZDQzYSZEYmJJZD02NDUzNzQ3MTkyNjUyZTEzYjc2ZmRlZDAmT3V0SVA9MTE3LjU3LjEwNS4xMDc%3D'))
// 引入axios库
import axios from 'axios'

// 请求地址
axios.defaults.baseURL = window.PLATFROM_CONFIG.baseUrl

axios.interceptors.request.use(config => {
  // 请求拦截器 显示进度条
  const token = window.sessionStorage.getItem('token')
  if (token) {
    config.headers['Content-Type'] = 'application/json'
    config.headers['vcToken'] = window.sessionStorage.getItem('token')
  }
  const RequestData = config.data ? config.data : {}  // 请求参数
  if( RequestData) RequestData.nTimestamp = moment().format('X')  // 添加时间戳
  const formData = {
    strSign: md5(JSON.stringify(RequestData) + '12u34%56X8032asGdfg3h!sA%SDFl2'),
    strContext: JSON.stringify(RequestData)
  }
  config.data = formData
  NProgress.start()
  return config
})
axios.interceptors.response.use(config => {
  // 响应拦截器 隐藏进度条
    const res = config.data
    if(res.nResult != 1){
      Message({
        message: res.Data || res.vcResult,
        type: 'error',
        duration: 3000
      })
      NProgress.done()
      return config
    }
  NProgress.done()
  return config
},error => {
  console.log(error.response.data) // for debug
  if(error.response.data.nResult === -5){
    Message({
      message:error.response.data.vcResult,
      type: 'error',
      duration: 3000
    })
    window.sessionStorage.removeItem('token')
    window.sessionStorage.removeItem('userName')
    window.sessionStorage.removeItem('routerName')
    
    setTimeout(() => {
      router.push('/login')
      setTimeout(() => {
        location.reload()
      }, 1000);
    }, 3000);
  }else{
    Message({
      message:error.response.data.vcResult,
      type: 'error',
      duration: 3000
    })
  }
  NProgress.done()
  return Promise.reject(error)
})



// 重写sessionStorage
Vue.prototype.setSessionItem = function (key, newVal) {
  // 创建 StorageEvent 事件
  let newStorageEvent = document.createEvent("StorageEvent");
  const storage = {
    setItem: function (k, val) {
      sessionStorage.setItem(k, val);
      // 初始化 StorageEvent 事件
      newStorageEvent.initStorageEvent(
        "setItem", // 事件别名
        false,
        false,
        k,
        null,
        val,
        null,
        null
      );
      // 派发事件
      window.dispatchEvent(newStorageEvent);
    },
  };
  return storage.setItem(key, newVal);
}

// 挂载到Vue原型实例上
Vue.prototype.$axios = axios
// 注册为全局组件
Vue.config.productionTip = false
new Vue({
  router,
  render: h => h(App)
}).$mount('#app')

