import Vue from 'vue'
import VueRouter from 'vue-router'

const Home = () => import(/* webpackChunkName:"Home" */ '../components/Home.vue')
const basehome = () => import(/* webpackChunkName:"basehome" */ '../components/basehome.vue')
const aboutUs = () => import(/* webpackChunkName:"aboutUs" */ '../components/aboutUs.vue')
const Feature = () => import(/* webpackChunkName:"Feature" */ '../components/Feature.vue')
const download = () => import(/* webpackChunkName:"download" */ '../components/download.vue')

const login = () => import(/* webpackChunkName:"login" */ '../components/login.vue')
const register = () => import(/* webpackChunkName:"register" */ '../components/register.vue')
const yunServices = () => import(/* webpackChunkName:"yunServices" */ '../components/yunServices.vue')


Vue.use(VueRouter)
const router = new VueRouter({
  mode:'history',
  routes: [
    {
      path: '/',
      redirect: '/basehome'
    },
    {
      path: '/Home',
      component: Home,
      redirect: '/basehome', 
      children: [
        { path: '/basehome', component: basehome },
        { path: '/aboutUs', component: aboutUs },
        { path: '/Feature', component: Feature },
        { path: '/download', component: download },
        { path: '/login', component: login },
        { path: '/register', component: register },
        { path: '/yunServices', component: yunServices },
      ]
    }
  ]
})

// 挂载路由导航守卫

router.beforeEach((to, from, next) => {
  // console.log(to.path)
  // console.log(from)
  // to 用户要访问的路径
  // from 代表从那个路径跳转而来
  // next 是一个函数，表示放行
  // 如果用户要访问 Login 给他访问,放行
  // if (to.path === '/login'){
  //   console.log('/login')
  // }
  // 如果不是  看储存是否有 token
  // const tokenStr = window.sessionStorage.getItem('token')
  // 如果没有token 就让用户返回到 login 界面
  // if (!tokenStr) return next('/Login')
  // 如果有就放行
  next()
})

export default router
